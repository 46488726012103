import React, {useState,useEffect} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';

const token = (localStorage.getItem("tok"));
const VerifyPassword = (props) => {
    const [code, setCode] = useState("")
    const [err, setErr] = useState("")
    const [error, setError] = useState("")
    const [pass, setPass] = useState("")
    const {id} = useParams()

    const [data, setData] = useState({
        email: id,
        code: "",
        password: "",
    })

    function handleclick(e) {
        e.preventDefault()
        if (data.password != pass) {
            setError("New Password and Confirm Password Doesn't Match");
          }
          else{
        axios.post(`${process.env.REACT_APP_API}/customer/verifyEmailCode`, data, { headers: { "authtoken": `${token}` } })
            .then((res) => {
                setCode("");

                if (res.data.data == "Code Invalid") {
                    setErr(res.data.data)
                }
                else if (res.data.data == "Password Updated") {
                    setErr(res.data.data)
                    window.location.href= "/login"
                }

            }
            )}
    }

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      };

  return (
    <div>
        <div class="page-heading">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="page-title">
                                <h2>Verify Password</h2>
                            </div>
                        </div>
                        {/* <!--col-xs-12--> */}
                    </div>
                    {/* <!--row--> */}
                </div>
                {/* <!--container--> */}
            </div>

            <div class="main-container col1-layout wow bounceInUp animated animated" style={{ visibility: "visible" }}>

<div class="main" style={{ marginBottom: "30px" }}>
    <div class="account-login container">
        {/* <!--page-title--> */}

        <form action="#" method="post" id="login-form" onSubmit={handleclick}>
            <input name="form_key" type="hidden" value="EPYwQxF6xoWcjLUr" />
            <fieldset class="col2-set">
                
                {/* <!--col-2 registered-users--> */}
                <div class="col-2 registered-users">
                    {/* <strong>Registered Customers</strong> */}
                    <div class="content">

                        {/* <p>If you don't have an account with us, please register.</p> */}
                        <ul class="form-list">
                        
                            <li>
                                <label for="email">Enter Verification Code<em class="required">*</em></label>
                                <div class="input-box">
                                    <input type="text" name="code" id="code" onChange={handleChange} class="input-text required-entry validate-email" placeholder='Enter verify code' title="verify code" />
                                </div>
                            </li>
                            <li>
                                <label for="email">Enter New password<em class="required">*</em></label>
                                <div class="input-box">
                                   <input type="password" name="password" onChange={handleChange} placeholder='Enter new password' class="input-text required-entry validate-password" id="pass" title="Password" />
                                </div>
                            </li>
                            <li>
                                <label for="email">Enter Confirm Password<em class="required">*</em></label>
                                <div class="input-box">
                                    <input type="password" name="Confirm password" onChange={(e) => setPass(e.target.value)} placeholder='Enter Confirm password' class="input-text required-entry validate-password" id="pass" title="Password" />
                                </div>
                            </li>
                        </ul>
                        <div class="remember-me-popup">
                            <div class="remember-me-popup-head" style={{ display: "none" }}>
                                <h3 id="text2">What's this?</h3>
                                <a href="#" class="remember-me-popup-close" onClick="showDiv()" title="Close">Close</a>
                            </div>
                            <div class="remember-me-popup-body" style={{ display: "none" }}>
                                <p id="text1">Checking "Remember Me" will let you access your shopping cart on this computer when you are logged out</p>
                                <div class="remember-me-popup-close-button a-right">
                                    <a href="#" class="remember-me-popup-close button" title="Close" onClick="showDiv()"><span>Close</span></a>
                                </div>
                            </div>
                        </div>

                        {/* <p class="required">* Required Fields</p> */}



                        <div class="buttons-set">

                            <button class="button login" name="send" id="send2"><span>Update</span></button>

                            {/* <a href="/forgotpassword" class="forgot-word">Already have an account ?</a> */}
                        </div>
                        <span style={{ color: "red" }} className="mb-1">
                                {" "}
                                {error}{" "}
                            </span>
                        {/* <!--buttons-set--> */}
                    </div>
                    {/* <!--content--> */}
                </div>

                {/* col-1 new user */}
                <div class="col-1 new-users">
                    <strong>New Customers</strong>
                    <div class="content">

                        <p>Kindly mention your email to verify the code,Set the new password and you can change your password..</p>
                        <div class="buttons-set">
                           {/* <a href='/register'> <button type="button" title="Create an Account" class="button create-account"><span><span>Create an Account</span></span></button></a> */}
                        </div>
                    </div>
                </div>
            </fieldset>
            {/* <!--col2-set--> */}
        </form>

    </div>
    {/* <!--account-login--> */}

</div>
{/* <!--main-container--> */}

</div>

    </div>
  )
}

export default VerifyPassword