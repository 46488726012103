import MainRoutes from "./Routes/MainRoutes";

function App() {
  return (
    <div className="App">
    <MainRoutes/>
    </div>
  );
}

export default App;
