import React from 'react'
import { Link } from 'react-router-dom'

const OrderSuccess = () => {
    function Updateee() {

        window.location.href = `/shop`
        // props.history.push("/Product?id=" + id)
    }

    return (
        <div>

            <div class="page-heading">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="page-title">
                                <h2>Success Order</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div >
                <div class="container mt-4 mb-4" >
                    <div class="row d-flex cart align-items-center justify-content-center">
                        <div class="col-md-10 text-center" >
                            <img src="/images/undraw.svg" alt="OrderSuccess" style={{ width: "50%", textAlign: "center" }} />
                            <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
                                <tfoot>
                                    <tr class="first last" >
                                        <td colspan="50" class="a-right last" >
                                            <Link to='/shop' style={{ paddingRight: "30px" }}><button type="button" title="Checkout" class="button btn-continue"><span>Continue Shopping</span></button></Link>
                                            <Link to='/dashboard'><button type="submit" value="empty_cart" class="button "><span>Go To Your Orders </span></button></Link>
                                            {/* <button type="submit" name="update_cart_action" value="update_qty" title="Update Cart" class="button btn-update"><span><span>Update Cart</span></span></button> */}

                                        </td>
                                    </tr>
                                </tfoot></div>
                        </div>
                    </div>

                </div></div>



            <div class="container">
                <div class="row our-features-box">
                    <ul>
                        <li>
                            <div class="feature-box">
                                <div class="icon-truck"></div>
                                <div class="content">FREE SHIPPING ON ORDER ABOVE RS.1000</div>
                            </div>
                        </li>
                        <li>
                            <div class="feature-box">
                                <div class="icon-support"></div>
                                <div class="content">
                                    Have a question?
                                    <br />
                                    <a href="tel:+91 90959-59587"> +91 86103 80320</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="feature-box">
                                <div class="icon-money"></div>
                                <div class="content">NO REFUND POLICY & 10 DAYS RETURN SERVICE AVAILABLE</div>
                            </div>
                        </li>
                        <li>
                            <div class="feature-box">
                                <div class="icon-return"></div>
                                <div class="content">Entice yourself with delicious sweets!</div>
                            </div>
                        </li>
                        {/* <li class="last">
              <div class="feature-box">
                {" "}
                <a href="#">
                  <i class="fa fa-apple"></i> download
                </a>{" "}
                <a href="#">
                  <i class="fa fa-android"></i> download
                </a>{" "}
              </div>
            </li> */}
                        <li style={{ marginTop: "70px", marginBottom: "10px" }}>
                            <button type="button" title="shop" class="button btn-continue" onClick={Updateee} ><span>GO TO SHOPPING</span></button>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default OrderSuccess