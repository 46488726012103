import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BrowserRouter as Router, Link, useLocation, useNavigate } from "react-router-dom";

const token = (localStorage.getItem('tok'));
const OrderSummary = () => {
    const singleproduct = (id) => {
        // props.history.push("/product?id=" + id)
    }
    const history = useNavigate()
    const [list, setlist] = useState([])
    const [orders, setOrders] = useState([])

    const [load, setLoad] = useState(true);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    function Updateee() {

        window.location.href = `/shop`
        // props.history.push("/Product?id=" + id)
    }
    let query = useQuery();

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API}/customer/getOrders`, {},
              { headers: { "authtoken": `${token}` } })
      
              .then((res) => {
                console.log("get orders",res.data.data);
                setLoad(false) ;
                  setOrders(res?.data?.data);
              });
      }, []);
      

    useEffect(() => {
        const id = query.get("id");
        axios.post(`${process.env.REACT_APP_API}/customer/getSingleOrder`, { "id": id }, { headers: { "authtoken": `${token}` } })
            .then((res) => {
                setLoad(false)
console.log("sdf",res?.data?.data);
                setlist(res?.data?.data)
            })
    }, [])


    return load ? (
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
            <div class="page-heading">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="page-title">
                                <h2>Order Summary</h2>
                            </div>
                        </div>
                        {/* <!--col-xs-12--> */}
                    </div>
                    {/* <!--row--> */}
                </div>
                {/* <!--container--> */}
            </div>

            {/* order Summary */}
            <div class="main-container col1-layout wow bounceInUp animated">

                <div class="main">
                    <div class="cart wow bounceInUp animated">

                        <div class="table-responsive shopping-cart-tbl  container">
                            <form action="#" method="post">
                                <input name="form_key" type="hidden" value="EPYwQxF6xoWcjLUr" />
                                <fieldset>
                                    <table id="shopping-cart-table" class="data-table cart-table table-striped">
                                            <colgroup><col />
                                            <col />
                                                <col />
                                                <col />
                                                <col />
                                                <col />
                                                <col />

                                        </colgroup><thead>
                                            <tr class="first last">
                                                    <th style={{ textAlign: "center", verticleAlign: "middle" }}>S.No</th>
                                                    <th style={{ textAlign: "center", verticleAlign: "middle" }}><span class="nobr">Order Id</span></th>
                                                    <th style={{ textAlign: "center", verticleAlign: "middle" }}>Total Amount</th>
                                                    <th class="a-center" colspan="1" style={{ textAlign: "center", verticleAlign: "middle" }}><span class="nobr">Order Status</span></th>
                                                    <th class="a-center" style={{ textAlign: "center", verticleAlign: "middle" }}>Payment Status</th>
                                                    <th class="a-center" colspan="1" style={{ textAlign: "center", verticleAlign: "middle" }}>Address</th>
                                                    {/* <th  class="a-center">Total</th> */}
                                                {/* <th rowspan="1" class="a-center" style={{ textAlign: "center", verticleAlign: "middle" }}>Action</th> */}
                                            </tr>
                                        </thead>
                                        {/* <tfoot>
                                            <tr class="first last">
                                                <td colspan="50" class="a-right last">
                                                    <button type="button" title="Checkout" class="button btn-continue" onClick={Update}><span>Proceed to Checkout</span></button>
                                                    <a href='/shop'><button type="submit" value="empty_cart" class="button "><span>Continue Shopping </span></button></a>
                                                    <button type="submit" name="update_cart_action" value="update_qty" title="Update Cart" class="button btn-update"><span><span>Update Cart</span></span></button>

                                                </td>
                                            </tr>
                                        </tfoot> */}
                                        <tbody>
                                                {/* {Array.isArray(list) && list.length != 0 ? list?.orderFetched?.map((c, val) => ( */}
                                                {Array.isArray(orders) && orders.length != 0 ?orders?.map((x, i) => (

                                                <tr class="first last odd">
                                                        <td style={{ textAlign: "center", verticleAlign: "middle" }}>{++i}.</td>
                                                        <td class="image hidden-table" style={{ textAlign: "center", verticleAlign: "middle" }}><a class="product-image">
                                                        {x?.order?.order_id}</a></td>
                                                        <td style={{ textAlign: "center", verticleAlign: "middle" }}>
                                                        Rs.{x?.order?.total_payment / 100}/-
                                                    </td>
                                                        <td class="a-center hidden-table" style={{ textAlign: "center", verticleAlign: "middle" }}>
                                                        {x?.order?.order_status}
                                                    </td>


                                                        <td class="a-center hidden-table" style={{ textAlign: "center", verticleAlign: "middle" }}>
                                                        <span class="cart-price">
                                                        {x?.order?.payment_status}
                                                        </span>


                                                    </td>

                                                        <td style={{ textAlign: "center", verticleAlign: "middle" }}>{orders[0]?.landmark},{orders[0]?.street},{orders[0]?.city},<br/>{orders[0]?.state}, {orders[0]?.zipcode}</td>
                                                    {/* <td class="a-center last">

                                                        <a onClick={() => delcart(x)} title="Remove item" class="button remove-item">
                                                            <span><span>Remove item</span></span></a>
                                                    </td> */}





                                                </tr>
                                            // )):(
                                            //     <h3 className="container text-center" style={{fontWeight:"bold"}}>"No Product Found"</h3>
                                            //   )
                                            //   }
                                            )):(
                                                <h3 className="container text-center" style={{fontWeight:"bold"}}>"No order Found"</h3>
                                              )
                                              }
                                        </tbody>
                                    </table>

                                </fieldset>
                            </form>
                        </div>

                        {/* <!-- BEGIN CART COLLATERALS --> */}

                        {/* <!--col1-layout--> */}

                        <div class="container">
                                <div class="row our-features-box">
                                    <ul>
                                        <li>
                                            <div class="feature-box">
                                                <div class="icon-truck"></div>
                                                <div class="content">FREE SHIPPING ON ORDER ABOVE RS.1000</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="feature-box">
                                                <div class="icon-support"></div>
                                                <div class="content">
                                                    Have a question?
                                                    <br />
                                                    <a href="tel:+91 90959-59587"> +91 86103 80320</a>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="feature-box">
                                                <div class="icon-money"></div>
                                                <div class="content">NO REFUND POLICY & 10 DAYS RETURN SERVICE AVAILABLE</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="feature-box">
                                                <div class="icon-return"></div>
                                                <div class="content">Entice yourself with delicious sweets!</div>
                                            </div>
                                        </li>
                                        <li >
                                            {/* <div class="feature-box">
                        {" "}
                        <a href="#">
                          <i class="fa fa-apple"></i> download
                        </a>{" "}
                        <a href="#">
                          <i class="fa fa-android"></i> download
                        </a>{" "}
                      </div> */}
                                        </li>
                                        <li style={{ marginTop: "70px", marginBottom: "10px" }}>
                                            <button type="button" title="shop" class="button btn-continue" onClick={Updateee}><span>GO TO SHOPPING</span></button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        {/* <!-- For version 1,2,3,4,6 --> */}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderSummary