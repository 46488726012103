import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";


const WhyUyir = () => {
  
  const [getCategory, setGetCategory] = useState([]);
  const navigate = useNavigate();


    function Updateee() {
        window.location.href = `/shop`
        // props.history.push("/Product?id=" + id)
      }

      function Update(id) {
        window.location.href = `/categoryShop/` + id;
        // props.history.push("/CategoryShop?id=" + id)
      }

      useEffect(() => {
        axios
          .get(`${process.env.REACT_APP_API}/customer/getCategory`)
          .then((res) => {
            setGetCategory(res.data.data);
            ;
            // ;
          })
          .catch(function (error) {
          });
      }, []);
      
  return (
    <div>
      <div class="page-heading">
        <div class="breadcrumbs">
          <div class="container">
            <div class="row">
              <div class="col-xs-12">
                <ul>
                  <li class="home">
                    {" "}
                    <Link to="/" title="Go to Home Page">
                      Home
                    </Link>{" "}
                    <span>&rsaquo; </span>{" "}
                  </li>
                  <li class="category1601">
                    {" "}
                    <strong>Why Uyirtheeni</strong>{" "}
                  </li>
                </ul>
              </div>
              {/* <!--col-xs-12-->  */}
            </div>
            {/* <!--row-->  */}
          </div>
          {/* <!--container-->  */}
        </div>
        <div class="page-title">
          <h2>Why Uyirtheeni?</h2>
        </div>
      </div>

      {/* <!-- BEGIN Main Container --> */}
      <div class="main-container col2-left-layout">
        <div class="main container">
          <div class="row">
            <div class="col-left sidebar col-sm-3 blog-side">
              <div id="secondary" class="widget_wrapper13" role="complementary">
                <div
                  id="recent-posts-4"
                  class="popular-posts widget widget__sidebar wow bounceInUp animated animated"
                  style={{ visibility: "visible" }}
                >
                  {/* <!-- Banner Ad Block --> */}
                  <div class="custom-slider">
                    <div>
                      <div
                        id="carousel-example-generic"
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <ol class="carousel-indicators">
                          <li
                            class="active"
                            data-target="#carousel-example-generic"
                            data-slide-to="0"
                          ></li>
                          <li
                            data-target="#carousel-example-generic"
                            data-slide-to="1"
                            class=""
                          ></li>
                          <li
                            data-target="#carousel-example-generic"
                            data-slide-to="2"
                            class=""
                          ></li>
                        </ol>
                        <div class="carousel-inner">
                          <div class="item active">
                            <img src="images/whyuyir2 1 (1).jpg" alt="slide3" /> 
                            <div class="carousel-caption">
                              <h4>Our Savouries</h4>
                              <h3>
                                <a
                                  title=" Sample Product"
                                  href="product-detail.html"
                                >
                                  Special Offers
                                </a>
                              </h3>
                              <a class="link" href="/shop">
                                Shop Now
                              </a>
                            </div>
                          </div>
                          <div class="item">
                            <img src="images/sesame-oil-raw-black-sesame-seeds 1 (1).jpg" alt="slide1" />
                            <div class="carousel-caption">
                              <h4>Our Oils</h4>
                              <h3>
                                <a
                                  title=" Sample Product"
                                  href="product-detail.html"
                                >
                                  Mega Sale
                                </a>
                              </h3>
                              <a class="link" href="/shop">
                              Shop Now
                              </a>
                            </div>
                          </div>
                          <div class="item">
                            <img src="images/whyuyir12 1 (1).jpg" alt="slide2" />
                            <div class="carousel-caption">
                              <h4>Soup Mix</h4>
                              <h3>
                                <a
                                  title=" Sample Product"
                                  href="product-detail.html"
                                >
                                  Special Offers
                                </a>
                              </h3>
                              <a class="link" href="/shop">
                                Shop Now
                              </a>
                            </div>
                          </div>
                        </div>
                        <a
                          class="left carousel-control"
                          href="#carousel-example-generic"
                          data-slide="prev"
                        >
                          {" "}
                          <span class="sr-only">Previous</span>{" "}
                        </a>{" "}
                        <a
                          class="right carousel-control"
                          href="#carousel-example-generic"
                          data-slide="next"
                        >
                          {" "}
                          <span class="sr-only">Next</span>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <!--widget-content-->  */}
                </div>
                <div
                  id="categories-2"
                  class="widget widget_categories wow bounceInUp animated animated"
                  style={{ visibility: "visible" }}
                >
                  <h2 class="widget-title">Categories</h2>
                  <div class="content">
                    <ul>
                    {getCategory.map((x) => {
                    return (
                      <li>
                        <a onClick={() => Update(x.id)} style={{textTransform:"capitalize",cursor:"pointer"}}>{x.category_name}</a>
                      </li>
                      // <li>
                      //   <a href="/shop">Uyirtheeni's Savouries</a>
                      // </li>
                      // <li>
                      //   <a href="/shop">
                      //     Uyritheeni's Health Mix & Millet Flour
                      //   </a>
                      // </li>
                      // <li>
                      //   <a href="/shop">Uyirtheeni's Idly & Rice Powder</a>
                      // </li>
                      // <li>
                      //   <a href="/shop">Uyirtheeni's Soup Mix</a>
                      // </li>
                      // <li>
                      //   <a href="/shop">Uyirtheeni's Grocery</a>
                      // </li>
                       );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-main col-sm-9 main-blog">
              <div id="main" class="blog-wrapper">
                <div id="primary" class="site-content">
                  <div id="content" role="main">
                    <article
                      id="post-29"
                      class="blog_entry clearfix wow bounceInUp animated animated"
                      style={{ visibility: "visible" }}
                    >
                      <div class="entry-content">
                        <div class="featured-thumb">
                          <a >
                            <img src="images/Desi Cow.jpg" alt="blog-img3" />
                          </a>
                        </div>
                        <header class="blog_entry-header clearfix">
                          <div class="blog_entry-header-inner">
                            <h2 class="blog_entry-title">
                              {" "}
                              <a  rel="bookmark">
                              Desi Cow
                              </a>{" "}
                            </h2>
                          </div>
                          {/* <!--blog_entry-header-inner-->  */}
                        </header>
                        <div class="entry-content">
                          
                          <p>
                          Desi Cow Ghee used as an ayurvedic medicine and a staple in Indian cooking, cow ghee contains many vital nutrients which help in making the body healthy and immune to diseases. It impacts large portions of the body from the eye to the abdomen; even the bones tend to get strong due to consumption of ghee.</p>

                          <p>Another health benefit of Desi cow ghee is that it is also used on wounds to speed up healing. However, one should note that since cow ghee is high in fat content, it should not be consumed by obese or people with heart and kidney problems.
                          </p>
                        </div>
                        <p>
                          {/* {" "}
                          <a href="#" class="btn">
                            Read More
                          </a>{" "} */}
                        </p>
                      </div>
                    </article>
                    <article
                      id="post-29"
                      class="blog_entry clearfix wow bounceInUp animated animated"
                      style={{ visibility: "visible" }}
                    >
                      <div class="entry-content">
                        <div class="featured-thumb">
                          <a >
                            <img src="images/27-824-465.jpg" alt="blog-img2" />
                          </a>
                        </div>
                        <header class="blog_entry-header clearfix">
                          <div class="blog_entry-header-inner">
                            <h2 class="blog_entry-title">
                              {" "}
                              <a  rel="bookmark">
                              Fresh Wood Pressed Groundnut Oil
                              </a>{" "}
                            </h2>
                          </div>
                          {/* <!--blog_entry-header-inner-->  */}
                        </header>
                        <div class="entry-content">
                          
                          <p>
                          A high level of vitamin E and monounsaturated and polyunsaturated fats collectively known as unsaturated good fat in groundnut oil is good for the heart. As per a few studies, replacing saturated fats with these types of unsaturated fats contribute to a lower risk of heart disease. Groundnut oil is 100% cholesterol-free and cholesterol is the main consideration that adds to convoluted heart conditions. 
                          </p>
                          <p>
                          It contains antioxidants that shield the body from toxins and free radicals. Groundnut oil additionally contains vitamin E and phytochemicals that aids in diminishing aggravation and battling cancer cells. 
                          </p>
                        </div>
                        <p>
                          {/* {" "}
                          <a  class="btn">
                            Read More
                          </a>{" "} */}
                        </p>
                      </div>
                    </article>
                    <article
                      id="post-29"
                      class="blog_entry clearfix wow bounceInUp animated animated"
                      style={{ visibility: "visible" }}
                    >
                      <div class="entry-content">
                        <div class="featured-thumb">
                          <a >
                            <img src="images/whyuyir3.jpg" alt="blog-img1" />
                          </a>
                        </div>
                        <header class="blog_entry-header clearfix">
                          <div class="blog_entry-header-inner">
                            <h2 class="blog_entry-title">
                              {" "}
                              <a  rel="bookmark">
                              MILLETS THAT ADDED TO PROCESSED FOODS TO ENRICH THEM.
                              </a>{" "}
                            </h2>
                          </div>
                          {/* <!--blog_entry-header-inner-->  */}
                        </header>
                        <div class="entry-content">
                          
                          <p>
                            {" "}
                            Millets are rich in niacin, which helps your body manage more than 400 enzyme reactions. Niacin is also important for healthy skin and organ function. In fact, it’s such an important compound that it’s often added to processed foods to enrich them.
                          </p>
                          <p>
                          Millet, especially the darker varieties, is also an excellent source of beta-carotene. This natural pigment acts as both an antioxidant and as a precursor to vitamin A, helping your body fight off free radicals and supporting the health of your eyes.
                          </p>
                        </div>
                        <p>
                          {/* {" "}
                          <a  class="btn">
                            Read More
                          </a>{" "} */}
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
                {/* <div class="pager">
                  <div class="pages">
                    <ol class="pagination">
                      <li class="active">
                        <a >1</a>
                      </li>
                      <li>
                        <a class="button" href="blog905b.html?p=2">
                          2
                        </a>
                      </li>
                      <li>
                        <a class="button" href="blog905b.html?p=2">
                          3
                        </a>
                      </li>
                      <li>
                        <a class="button" href="blog905b.html?p=2">
                          4
                        </a>
                      </li>
                      <li>
                        <a class="button" href="blog905b.html?p=2">
                          5
                        </a>
                      </li>
                      <li>
                        <a class="button" href="blog905b.html?p=2">
                          6
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a
                          class="button next i-next"
                          href="blog905b.html?p=2"
                          title="Next"
                        >
                          {" "}
                          »{" "}
                        </a>{" "}
                      </li>
                    </ol>
                  </div>
                </div> */}
              </div>
              {/* <!--#main wrapper grid_8-->  */}
            </div>
            {/* <!--col-main col-sm-9-->  */}
          </div>
        </div>
        {/* <!--main-container-->  */}
      </div>

      <div class="container">
        <div class="row our-features-box">
          <ul>
            <li>
              <div class="feature-box">
                <div class="icon-truck"></div>
                <div class="content">FREE SHIPPING ON ORDER ABOVE RS.1000</div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-support"></div>
                <div class="content">
                  Have a question?
                  <br />
                  <a href="tel:+91 90959-59587"> +91 86103 80320</a>
                </div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-money"></div>
                <div class="content">NO REFUND POLICY & 10 DAYS RETURN SERVICE AVAILABLE</div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-return"></div>
                <div class="content">Entice yourself with delicious sweets!</div>
              </div>
            </li>
              <li>
                {/* <div class="feature-box">
                {" "}
                <a >
                  <i class="fa fa-apple"></i> download
                </a>{" "}
                <a >
                  <i class="fa fa-android"></i> download
                </a>{" "}
              </div> */}
              </li>
              <li style={{ marginTop: "70px", marginBottom: "10px" }}>
                <button type="button" title="shop" class=" button btn-continue" onClick={Updateee}><span>GO TO SHOPPING</span></button>
            </li>
          </ul>
        </div>
      </div>

    </div>
  );
};

export default WhyUyir;
