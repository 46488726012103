/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-sequences */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiCalls } from "../Axios/Services";
const token = localStorage.getItem("tok");
var shippingPrice, addressValue, totalShipping;

const Checkout = (props) => {
  const history = useNavigate();
  const [address, setAddress] = useState([]);
  const [profilelist, setProfilelist] = useState([]);
  const [load, setLoad] = useState(true);
  const [id, setId] = useState(0);
  const [cartlist, setCartlist] = useState([]);
  const [selectaddress, setSelectaddress] = useState("");
  const [state, setState] = useState({
    subtotal: 0,
    total: 0,
    gst: 0,
  });

  
  function delcart(x) {
    axios
      .post(
        `${process.env.REACT_APP_API}/customer/removeCart`,
        { cart_id: x.id, product_id: x.product_id },
        { headers: { authtoken: `${token}` } }
      )
      .then((res) => {
        axios
          .post(
            `${process.env.REACT_APP_API}/customer/getCart`,
            {},
            { headers: { authtoken: `${token}` } }
          )
          .then((res) => {
            setLoad(false);
            setCartlist(res.data.data);
            window.location.reload();
            toast.error("Removed from Cart!", { autoClose: 2000, position: "bottom-center" });
          });
      });
  }

  function Update(id) {
    window.location.href = "/updateaddress?id=" + id;
  }

  function Updateee() {
    console.log("dtaa");
    window.location.href = `/shop`;
    // props.history.push("/Product?id=" + id)
  }

  useEffect(() => {
    // checkout()
    axios
      .post(
        `${process.env.REACT_APP_API}/customer/getCart`,
        {},
        {
          headers: { authtoken: `${token}` },
        }
    )
      .then((res) => {
        setLoad(false);
        setCartlist(res.data.data);
      })
      .catch((error) => {
        if (localStorage.tok == null || localStorage.tok == undefined) {
          // history.push("/login");
          window.location.href = "/login"
        }
      });

    // axios.post(`${process.env.REACT_APP_API}/customer/sumofproducts`, {}, {
    //   headers: { "authtoken": `${token}` }
    // }).then((res) => {
    //   setLoad(false)
    //   setState({
    //     total: res.data.data + res.data.data * 0.05,
    //     subtotal: res.data.data,
    //     gst: res.data.data * 0.05
    //   })
    // })
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}/customer/getAddress`,
        { headers: { "authtoken": `${token}` } }
      )
      .then((res) => {
        setLoad(false);
        setAddress(res?.data?.data);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //Shipping Fee Calculation

  let sumOfQuantity = 0;
  let sumOfTax = 0;
  let sumOfProducts = 0;
  cartlist.map(
    (data) => (
      (sumOfQuantity = sumOfQuantity + data.quantity),
      (sumOfTax = sumOfTax + data.tax),
      (sumOfProducts = sumOfProducts + data.total_price)
    )
  );
  const handleChange = (e, x) => {
    setSelectaddress(x.state);
    setId(e.target.value);
  };

  if (selectaddress === "Tamilnadu") {
    shippingPrice = 30;
    if (sumOfQuantity <= 1000) {
      shippingPrice = 30;
    } else {
      const quantity = sumOfQuantity - 1000;
      let remainder = quantity / 500;
      remainder = Math.ceil(remainder);
      remainder = remainder * 15;
      shippingPrice = shippingPrice + remainder;
    }
  }

  // Other state shipping price
  else {
    shippingPrice = 50;
    if (sumOfQuantity <= 1000) {
      shippingPrice = 50;
    } else {
      const quantity = sumOfQuantity - 1000;
      let remainder = quantity / 500;
      remainder = Math.ceil(remainder);
      remainder = remainder * 25;
      shippingPrice = shippingPrice + remainder;
    }
  }
  // addressValue = selectaddress === "Tamilnadu" ? 20 : 40;
  totalShipping = shippingPrice;
  var grandTotal = sumOfProducts + totalShipping;

  // payment
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpay() {
    if (id != 0) {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      // var result = (data) => {
      //   axios.post(`${process.env.REACT_APP_API}/customer/addOrders`, data, {
      //     headers: { "authtoken": `${token}` }
      //   });
      // }
      axios.post(`${process.env.REACT_APP_API}/customer/checkout`,{ address: id },{headers: { authtoken: `${token}` },})
        .then((res) => {
          setLoad(false);
          console.log("Logger 1");
          
          const options = {
            key: process.env.REACT_APP_MID_URL,
            amount: grandTotal * 100,
            // amount: grandTotal * 100,
            currency: "INR",
            name: "Uyirtheeni",
            description: "Buy Product",
            image: {},
            order_id: res.data.data.id,
            handler: async function (response) {
              const data = {
                razor_order_id: response.razorpay_order_id,
                razor_payment_id: response.razorpay_payment_id,
                razor_signature: response.razorpay_signature,
                payment_status: "SUCCESS",
                reason: "",
              };
              // props.history.push("/myprofile")
              // window.location.href = "/ordersuccess"
              setTimeout(() => {
                window.location.reload();
              }, 3000);
              result(data);
            },
            prefill: {
              name: profilelist.customername,
              email: profilelist.email,
              contact: profilelist.phone,
            },
            notes: {
              address: "",
            },
          };



          var result = (data) => {
            if (data.payment_status=="SUCCESS") {
              axios.post(`${process.env.REACT_APP_API}/customer/addOrders`, data, { headers: { authtoken: `${token}` }, }).then(()=>{
                window.location.href = "/ordersuccess"
              })
            }
            if (data.payment_status=="FAILURE") {
              axios.post(`${process.env.REACT_APP_API}/customer/addOrders`, data, { headers: { authtoken: `${token}` }, }).then(()=>{
                toast.error(data.reason);
              });
            }
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.on("payment.failed", function (response) {
            var failedResponse = {
              razor_order_id: response.error.metadata.order_id,
              razor_payment_id: response.error.metadata.payment_id,
              razor_signature: "None",
              payment_status: "FAILURE",
              reason: response.error.description,
            };
            result(failedResponse);
          });
          paymentObject.open();
        });

      // addressValue = selectaddress === "Tamilnadu" ? 20 : 40
      // totalShipping = addressValue + shippingPrice
    } else {
      toast.error("Please Select Address", { autoClose: 2000, position: "bottom-center" });
    }
  }

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
        <div class="page-heading">
        <div class="container">
            <div class="row">
              <div class="col-xs-12">
                <div class="page-title">
                  <h2>Checkout</h2>
                </div>
              </div>
            </div>
        </div>
        </div>

        {/* <!-- BEGIN Main Container -->   */}

        <div class="main-container col1-layout wow bounceInUp animated">
        <div class="main">
            <div class="cart wow bounceInUp animated">
              <div class="table-responsive shopping-cart-tbl  container">
                <form action="#" method="post">
                  <input name="form_key" type="hidden" value="EPYwQxF6xoWcjLUr" />
                  <fieldset>
                    <table
                      id="shopping-cart-table"
                      class="data-table cart-table table-striped"
                    >
                      <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                      </colgroup>
                      <thead>
                        <tr class="first last">
                          <th >S.No</th>
                          <th >
                            <span class="nobr">Image</span>
                          </th>
                          <th >Product Name</th>
                          <th >
                            <span class="nobr">Category</span>
                          </th>
                          <th >Price</th>
                          <th >Quantity</th>
                          {/* <th >Tax</th> */}
                          <th >Total</th>
                        </tr>
                      </thead>
                      {/* <tfoot>
                                    <tr class="first last">
                                        <td rowspan="50" class="a-right last">
                                            <button type="button" title="Continue Shopping" class="button btn-continue" onClick=""><span>Continue Shopping</span></button>
                                            <button type="submit" name="update_cart_action" value="empty_cart" class="button " id="empty_cart_button"><span><span>Proceed to Checkout</span></span></button>
                                            <button type="submit" name="update_cart_action" value="update_qty" title="Update Cart" class="button btn-update"><span><span>Update Cart</span></span></button>

                                        </td>
                                    </tr>
                                </tfoot> */}
                      <tbody>
                        {cartlist?.map((x, val) => (
                          <tr class="first last odd">
                            <td>{++val}</td>
                            <td class="image hidden-table">
                              <a class="product-image">
                                <img
                                  src={process.env.REACT_APP_API + x?.product.product_image}
                                  width="75"
                                  alt={x?.product.product_name}
                                />
                              </a>
                            </td>
                            <td>
                              <h2 class="product-name">
                                <a style={{ textTransform: "capitalize" }}>
                                  {x?.product.product_name}
                                </a>
                              </h2>
                            </td>
                            <td
                              class="a-center hidden-table"
                              style={{ textTransform: "capitalize" }}
                            >
                              <a href="#" class="edit">
                                {x?.product.category_name}
                              </a>
                            </td>

                            <td class="a-right hidden-table">
                              <span class="cart-price">
                                <span class="price">
                                  ₹{x?.single_product_price}
                                </span>
                              </span>
                            </td>
                            <td
                              class="a-center movewishlist"
                              style={{ textAlign: "center" }}
                            >
                              {x.quantity}g{" "}
                            </td>
                            {/* <td class="a-center last">₹{x.tax}</td> */}
                            <td>₹{x?.total_price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </fieldset>
                </form>
              </div>

              {/* <!-- BEGIN CART COLLATERALS --> */}

              <div class="cart-collaterals container">
                <div class="row">
                  <div class="col-sm-8" style={{ paddingTop: "35px" }}>
                    <div class="recent-orders" id="liton_tab_1_2">
                      <div class="title-buttons"> <strong>Address</strong> <a href="/addaddress">Add Address</a> </div>
                      <div class="table-responsive">
                        <table
                          class="data-table table-striped"
                          id="my-orders-table"
                        >
                          <colgroup>
                            <col width="" />
                            <col width="" />
                            <col />
                            <col width="1" />
                            <col width="1/" />
                          </colgroup>
                          <thead>
                            <tr class="first last">
                              <th>S.no</th>
                              <th>Address</th>
                              <th>State</th>
                              <th>Pincode</th>
                              <th>Edit</th>
                              <th>Select </th>
                            </tr>
                          </thead>
                          <tbody>
                            {address !== null
                              ? address?.map((x, val) => (
                                <tr class="first odd">
                                  <td>{++val}</td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    {x?.landmark}, {x?.street}, {x?.city}
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    <span class="nobr">{x?.state}</span>
                                  </td>
                                  <td class="a-center last">
                                    <span class="nobr">{x?.zipcode} </span>
                                  </td>
                                  <td>
                                    <span
                                      className=""
                                      type="submit"
                                      style={{
                                        cursor: "pointer",
                                        color: "#000",
                                        fontSize: "12px",
                                        padding: "10px",
                                      }}
                                      onClick={() => Update(x.id)}
                                    >
                                      {" "}
                                      <h4 class="bx bx-edit-alt"></h4>
                                    </span>
                                  </td>
                                  <td>
                                    <div style={{ paddingLeft: "20px" }}>
                                      <input
                                        type="checkbox"
                                        checked={x.id == id}
                                        onChange={(e) => handleChange(e, x)}
                                        value={x.id}
                                        required
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))
                              : false}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* <div class="col-sm-4">

                            <div class="discount">
                                <h3>Discount Codes</h3>
                                <form id="discount-coupon-form" action="#" method="post">
                                    <label for="coupon_code">Enter your coupon code if you have one.</label>
                                    <input type="hidden" name="remove" id="remove-coupone" value="0" />
                                    <input class="input-text fullwidth" type="text" id="coupon_code" name="coupon_code" value="" />
                                    <button type="button" title="Apply Coupon" class="button coupon " onClick="discountForm.submit(false)" value="Apply Coupon"><span>Apply Coupon</span></button>

                                </form>

                            </div>
                        </div> */}

                  <div class="col-sm-4">
                    <div class="totals">
                      <h3>Shopping Cart Total</h3>
                      <div class="inner">
                        <table
                          id="shopping-cart-totals-table"
                          class="table shopping-cart-table-total"
                        >
                          <colgroup>
                            <col />
                            <col width="1" />
                          </colgroup>
                          <tfoot>
                            <tr>
                              <td class="a-left" colspan="1">
                                <strong>Grand Total</strong>
                              </td>
                              <td class="a-right">
                                <strong>
                                  <span class="price">₹{grandTotal}</span>
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td class="a-left" colspan="1">
                                <p style={{ fontSize: "14px" }}>Inclusive GST *{" "}</p>
                              </td>
                              <td class="a-right">
                                <span class="price" style={{ fontSize: "12px" }}>
                                  ₹{sumOfTax} <span class="small"></span>{" "}
                                </span>{" "}
                              </td>
                            </tr>
                          </tfoot>
                          <tbody>
                            <tr>
                              <td class="a-left" colspan="1">
                                Subtotal{" "}
                              </td>

                              <td class="a-right">
                                <span class="price">₹{sumOfProducts}</span>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td class="a-left" colspan="1">
                                Shipping Charge{" "}
                              </td>

                              <td class="a-right">
                                <span class="price">
                                  ₹ {totalShipping ? totalShipping : 0}
                                </span>
                              </td>
                            </tr>
                          
                          </tbody>
                        </table>

                        <ul class="checkout">
                          <li>
                            <button
                              type="button"
                              title="Proceed to Checkout"
                              class="button btn-proceed-checkout"
                              onClick={displayRazorpay}
                            >
                              <span>Place Order</span>
                            </button>
                          </li>
                          <br />
                          <ToastContainer />
                          {/* <li><a href="multiple-addresses.html" title="Checkout with Multiple Addresses">Checkout with Multiple Addresses</a>
                                        </li> */}
                          <br />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--col1-layout--> */}

              <div class="container">
                <div class="row our-features-box">
                  <ul>
                    <li>
                      <div class="feature-box">
                        <div class="icon-truck"></div>
                        <div class="content">
                        FREE SHIPPING ON ORDER ABOVE RS.1000
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="feature-box">
                        <div class="icon-support"></div>
                        <div class="content">
                          Have a question?
                          <br />
                          <a href="tel:+91 90959-59587"> +91 86103 80320</a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="feature-box">
                        <div class="icon-money"></div>
                        <div class="content">
                        NO REFUND POLICY & 10 DAYS RETURN SERVICE AVAILABLE
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="feature-box">
                        <div class="icon-return"></div>
                        <div class="content">
                          Entice yourself with delicious sweets!
                        </div>
                      </div>
                    </li>
                    <li>
                      {/* <div class="feature-box">
                        {" "}
                        <a href="#">
                          <i class="fa fa-apple"></i> download
                        </a>{" "}
                        <a href="#">
                          <i class="fa fa-android"></i> download
                        </a>{" "}
                      </div> */}
                    </li>
                    <li style={{ marginTop: "70px", marginBottom: "10px" }}>
                      <button
                        type="button"
                        title="shop"
                        class="button btn-continue"
                        onClick={Updateee}
                      >
                        <span>GO TO SHOPPING</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- For version 1,2,3,4,6 --> */}
            </div>
        </div>
        </div>
      </div>
  );
};

export default Checkout;
