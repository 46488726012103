import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import img from "../Best Selling product/Coconut Candy .jpg"
import img1 from "../Best Selling product/Groundnut Idly & Rice Powder.jpg"
import img2 from "../Best Selling product/Jaggery Powder.jpg"
import img3 from "../Best Selling product/Karuppukavuni Payasam Mix.jpg"
import img4 from "../Best Selling product/Mudakathan Soup Mix.jpg"
import img5 from "../Best Selling product/Palm Sugar Candy.jpg"
import img6 from "../Best Selling product/Pearl Millet Pepper Karasev.jpg"
import img7 from "../Best Selling product/Urad Dal Porridge Mix.jpg"
import img8 from "../Deal of the week/Beetroot Malt.jpg"
import img9 from "../Deal of the week/Health Mix.jpg"
import img10 from "../Deal of the week/Little Millet Garlic Murukku.jpg"
import img11 from "../Deal of the week/Palm Jaggery Groundnut Candy.jpg"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const token = localStorage.getItem("tok");

const Home = (props) => {
  const [getCategory, setGetCategory] = useState([]);
  const [recProducts, setRecProducts] = useState([]);
  const [error, setError] = useState([]);
  const [load, setLoad] = useState(true);
  const [banner, setBanner] = useState([]);

  // GET BANNERS API CALL
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/customer/getBanners`, {
        headers: { authtoken: ` ${token}` },
      })
      .then((res) => {
        setLoad(false);
        setBanner(res.data.data);
      })
      .catch((e) => {
        props.history.push("/Apifails");
      });
  }, []);

  // GET CATEGORY API CALL
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/customer/getCategory`)
      .then((res) => {
        setLoad(false);
        setGetCategory(res.data.data);
        // ;
      })
      .catch(function (error) {
        setError("API CALL FAILS");
      });
  }, []);

  function Update(id) {
    window.location.href = `/categoryShop/` + id;
    // props.history.push("/CategoryShop?id=" + id)
  }
  function Updatee(id) {
    window.location.href = `/shopdetails/` + id;
    // props.history.push("/Product?id=" + id)
  }

  function Updateee() {
    window.location.href = `/shop`;
    // props.history.push("/Product?id=" + id)
  }
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API}/customer/recommendedProduct`, {
        recommended: true,
      })
      .then((res) => {
        setLoad(false);
        setRecProducts(res.data.data);
      });
  }, []);

  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div class="content">
        {/* <div id="thmg-slider-slideshow" class="thmg-slider-slideshow">
      <div class="container">
        <div id='thm_slider_wrapper' class='thm_slider_wrapper fullwidthbanner-container' >
          <div id='thm-rev-slider' class='rev_slider fullwidthabanner'>
            <ul>
              <li data-transition='random' data-slotamount='7' data-masterspeed='1000' data-thumb='images/slide-img1.jpg'><img src='images/slide-img2.jpg'  data-bgposition='left top'  data-bgfit='cover' data-bgrepeat='no-repeat' alt="slider-image1" />
                <div class="info">
                  <div class='tp-caption ExtraLargeTitle sft  tp-resizeme ' data-x='0'  data-y='220'  data-endspeed='500'  data-speed='500' data-start='1100' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none' data-elementdelay='0.1' data-endelementdelay='0.1' style={{zIndex:"2", whiteSpace:"nowrap"}}><span>Fresh Food</span></div>
                  <div class='tp-caption LargeTitle sfl  tp-resizeme ' data-x='0'  data-y='300'  data-endspeed='500'  data-speed='500' data-start='1300' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none' data-elementdelay='0.1' data-endelementdelay='0.1' style={{zIndex:"3", whiteSpace:"nowrap"}}>Simply <span>delicious</span></div>
                  <div class='tp-caption sfb  tp-resizeme ' data-x='0'  data-y='520'  data-endspeed='500'  data-speed='500' data-start='1500' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none' data-elementdelay='0.1' data-endelementdelay='0.1' style={{zIndex:"4", whiteSpace:"nowrap"}}><a href='#' class="buy-btn">Shop Now</a></div>
                  <div    class='tp-caption Title sft  tp-resizeme ' data-x='0'  data-y='420'  data-endspeed='500'  data-speed='500' data-start='1500' data-easing='Power2.easeInOut' data-splitin='none' data-splitout='none' data-elementdelay='0.1' data-endelementdelay='0.1' style={{zIndex:"4", whiteSpace:"nowrap"}}>We supply highly quality organic products</div>
                </div>
              </li>
              <li data-transition='random' data-slotamount='7' data-masterspeed='1000' data-thumb='images/slide-img3.jpg'><img src='images/slide-img3.jpg'  data-bgposition='left top'  data-bgfit='cover' data-bgrepeat='no-repeat' alt="slider-image2"  />
                <div class="info">
                  <div class='tp-caption ExtraLargeTitle sft  tp-resizeme ' data-x='0'  data-y='220'  data-endspeed='500'  data-speed='500' data-start='1100' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none' data-elementdelay='0.1' data-endelementdelay='0.1' style={{zIndex:"2", whiteSpace:"nowrap"}}><span>Fresh Look</span></div>
                  <div class='tp-caption LargeTitle sfl  tp-resizeme ' data-x='0'  data-y='300'  data-endspeed='500'  data-speed='500' data-start='1300' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none' data-elementdelay='0.1' data-endelementdelay='0.1' style={{zIndex:"3", whiteSpace:"nowrap"}}><span>100%</span> Organic</div>
                  <div class='tp-caption sfb  tp-resizeme ' data-x='0'  data-y='520'  data-endspeed='500'  data-speed='500' data-start='1500' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none' data-elementdelay='0.1' data-endelementdelay='0.1' style={{zIndex:"4", whiteSpace:"nowrap"}}><a href='#' class="buy-btn">Shop Now</a></div>
                  <div    class='tp-caption Title sft  tp-resizeme ' data-x='0'  data-y='420'  data-endspeed='500'  data-speed='500' data-start='1500' data-easing='Power2.easeInOut' data-splitin='none' data-splitout='none' data-elementdelay='0.1' data-endelementdelay='0.1' style={{zIndex:"4", whiteSpace:"nowrap"}}>Farm Fresh Produce Right to Your Door</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> */}

        {/* carousel */}
        <div class="slider-area">
          <Swiper
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap-reverse",
            }}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              backgroundColor: "white",
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {banner.map((x) => (
              <SwiperSlide>
                {/* slide{i} */}
                <a href="#">
                  <div
                    class="intro-section slider-content-center bg-img single-animation-wrap slider-bg-color-1"
                    style={{
                      aspectRatio: "16/9",
                      backgroundImage: `url(${x.banner_image})`,
                    }}
                  >
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                          {/* <div class="slider-content-1 slider-animated-1" id="carosel">
                                                        <h1 class="animated" style={{ color: "white" }}>Optimize Your Health</h1>
                                                        <h3 id='offer' style={{ fontSize: "25px" }}>Flat 15% OFF</h3>
                                                        <h1 class="animated" style={{ background: "rgb(242 242 242 / 60%)", paddingLeft: "20px" }}>
                                                          </h1>
                                                        <div class="slider-btn btn-hover">
                                                            <a href="/shop" class="btn animated" id='buttonan' target="_blank" style={{ padding: "10px" }}>
                                                                Shop Now <i className="fas fa-long-arrow-alt-right "></i></a>
                                                        </div>
                                                    </div> */}
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="hero-slider-img-1 slider-animated-1">
                            <img
                              class="animated animated-slider-img-1"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* category */}
        <div class="best-pro slider-items-products container">
          <div class="new_title">
            <h2>All Categories</h2>
          </div>
        </div>
        <div className="container">
          <div class="row">
            <div class="hot-deal">
              <div class="box-timer">
                <div class="countbox_1 timer-grid"></div>
              </div>

              <ul
                class="products-grid"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {getCategory.map((x) => {
                  return (
                    <li class="item col-xs-6 col-sm-3  col-md-3 col-lg-3 ">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => Update(x.id)}
                              class="product-image"
                            >
                              <img
                                src={
                                  process.env.REACT_APP_API + x.category_image
                                }
                                alt={x.category_name}
                              />
                            </a>
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => Update(x.id)}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                {x.category_name}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
                <ToastContainer Transition="zoom" />
              </ul>
            </div>
            {/* </div> */}
          </div>
        </div>

        <div id="top">
          <div class="container">
            <div class="row"></div>
          </div>
        </div>

        {/* best seller */}

        {/* <section class=" wow bounceInUp animated">
      <div class="best-pro slider-items-products container">
        <div class="new_title">
          <h2>Best Seller</h2>
          <h4>So you get to know me better</h4>
        </div>
      </div>
    </section> */}

        {/* best seller products */}

        {/* hot deals */}
        <div class="hot-section" style={{ marginBottom: "60px" }}>
          <div class="container">
            {/* <div class="row">
              <div class="ad-info">
                <h2>Hurry Up!</h2>
                <h3>Deal of the week</h3>
                <h4>From our family farm right to your doorstep.</h4>
              </div>
            </div> */}
            {/* <div class="best-pro slider-items-products container"> */}
            <div class="new_title">
              <h2>Hurry Up!</h2>
              <h3>Deal of the week</h3>
              <h4>From our family farm right to your doorstep.</h4>
            </div>
            {/* </div> */}
            <div class="row">
              <div class="hot-deal">
                <div class="box-timer">
                  <div class="countbox_1 timer-grid"></div>
                </div>
                <ul class="products-grid">
                  {/* {recProducts?.map((y) => ( */}
                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 3}
                              class="product-image"
                            >
                              <img
                                src={img8}
                                alt="{y?.product_name"
                              />
                            </a>
                            {/* <div class="new-label new-top-left">Hot</div> */}
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 3}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Beetroot Malt
                                {/* {Truncate(y?.product_name, 23)}{" "} */}
                              </a>{" "}
                            </div>
                            <br />
                            {/* <div class="item-price">
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹{y?.product_variants[0]?.discount_price}</span> <span style={{fontSize:"18px"}}><strike>₹{y?.product_variants[0]?.actual_price}</strike></span></span> </div>
                                    </div> */}
                            <div class="item-content">
                              <div class="rating">
                                <div class="ratings"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  {/* ))} */}

                  {/* new add */}
                  <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 29}
                              class="product-image"
                            >
                              <img
                                src={img9}
                                alt="{y?.product_name"
                              />
                            </a>
                            {/* <div class="new-label new-top-left">Hot</div> */}
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 29}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Health Mix
                                {/* {Truncate(y?.product_name, 23)}{" "} */}
                              </a>{" "}
                            </div>
                            <br />
                            {/* <div class="item-price">
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹{y?.product_variants[0]?.discount_price}</span> <span style={{fontSize:"18px"}}><strike>₹{y?.product_variants[0]?.actual_price}</strike></span></span> </div>
                                    </div> */}
                            <div class="item-content">
                              <div class="rating">
                                <div class="ratings"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 19}
                              class="product-image"
                            >
                              <img
                                src={img10}
                                alt="{y?.product_name"
                              />
                            </a>
                            {/* <div class="new-label new-top-left">Hot</div> */}
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 19}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Little Millet Garlic Murukku
                                {/* {Truncate(y?.product_name, 23)}{" "} */}
                              </a>{" "}
                            </div>
                            <br />
                            {/* <div class="item-price">
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹{y?.product_variants[0]?.discount_price}</span> <span style={{fontSize:"18px"}}><strike>₹{y?.product_variants[0]?.actual_price}</strike></span></span> </div>
                                    </div> */}
                            <div class="item-content">
                              <div class="rating">
                                <div class="ratings"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() =>window.location.href = `/shopdetails/` + 1}
                              class="product-image"
                            >
                              <img
                                src={img11}
                                alt="{y?.product_name"
                              />
                            </a>
                            {/* <div class="new-label new-top-left">Hot</div> */}
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() =>window.location.href = `/shopdetails/` + 1}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Palm Jaggery Groundnut Candy
                                {/* {Truncate(y?.product_name, 23)}{" "} */}
                              </a>{" "}
                            </div>
                            <br />
                            {/* <div class="item-price">
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹{y?.product_variants[0]?.discount_price}</span> <span style={{fontSize:"18px"}}><strike>₹{y?.product_variants[0]?.actual_price}</strike></span></span> </div>
                                    </div> */}
                            <div class="item-content">
                              <div class="rating">
                                <div class="ratings"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* best selling */}
        <div class="best-pro slider-items-products container">
          <div class="new_title">
            <h2>Best Selling Product</h2>
          </div>
        </div>
        <div className="container">
          <div class="row">
            <div class="hot-deal">
              <div class="box-timer">
                <div class="countbox_1 timer-grid"></div>
              </div>

              <ul class="products-grid">
                {/* {getCategory.map((x) => { */}
                  {/* return ( */}
                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 14}
                              class="product-image"
                            >
                              <img
                                src= {img}
                                // { process.env.REACT_APP_API + x.category_image }
                                alt="category_name"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() =>window.location.href = `/shopdetails/` + 14}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Coconut Candy 
                                {/* {Truncate(x.category_name, 25)} */}
                              </a>{" "}
                              {/* <div class="item-price" style={{paddingTop:"14px"}}>
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹125</span> <span style={{fontSize:"18px"}}><strike>₹150</strike></span></span> </div>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* new add */}
                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 32}
                              class="product-image"
                            >
                              <img
                                src= {img1}
                                // { process.env.REACT_APP_API + x.category_image }
                                alt="category_name"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 32}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Groundnut Idly & Rice Powder
                                {/* {Truncate(x.category_name, 25)} */}
                              </a>{" "}
                              {/* <div class="item-price" style={{paddingTop:"14px"}}>
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹125</span> <span style={{fontSize:"18px"}}><strike>₹150</strike></span></span> </div>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 6}
                              class="product-image"
                            >
                              <img
                                src= {img2}
                                // { process.env.REACT_APP_API + x.category_image }
                                alt="category_name"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 6}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Jaggery Powder 
                                {/* {Truncate(x.category_name, 25)} */}
                              </a>{" "}
                              {/* <div class="item-price" style={{paddingTop:"14px"}}>
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹125</span> <span style={{fontSize:"18px"}}><strike>₹150</strike></span></span> </div>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 104}
                              class="product-image"
                            >
                              <img
                                src= {img3}
                                // { process.env.REACT_APP_API + x.category_image }
                                alt="category_name"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 104}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Karuppukavuni Payasam Mix
                                {/* {Truncate(x.category_name, 25)} */}
                              </a>{" "}
                              {/* <div class="item-price" style={{paddingTop:"14px"}}>
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹125</span> <span style={{fontSize:"18px"}}><strike>₹150</strike></span></span> </div>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 40}
                              class="product-image"
                            >
                              <img
                                src= {img4}
                                // { process.env.REACT_APP_API + x.category_image }
                                alt="category_name"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 40}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Mudakathan Soup Mix
                                {/* {Truncate(x.category_name, 25)} */}
                              </a>{" "}
                              {/* <div class="item-price" style={{paddingTop:"14px"}}>
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹125</span> <span style={{fontSize:"18px"}}><strike>₹150</strike></span></span> </div>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 43}
                              class="product-image"
                            >
                              <img
                                src= {img5}
                                // { process.env.REACT_APP_API + x.category_image }
                                alt="category_name"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 43}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Palm Sugar Candy
                                {/* {Truncate(x.category_name, 25)} */}
                              </a>{" "}
                              {/* <div class="item-price" style={{paddingTop:"14px"}}>
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹125</span> <span style={{fontSize:"18px"}}><strike>₹150</strike></span></span> </div>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 20}
                              class="product-image"
                            >
                              <img
                                src= {img6}
                                // { process.env.REACT_APP_API + x.category_image }
                                alt="category_name"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 20}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Pearl Millet Pepper Karasev
                                {/* {Truncate(x.category_name, 25)} */}
                              </a>{" "}
                              {/* <div class="item-price" style={{paddingTop:"14px"}}>
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹125</span> <span style={{fontSize:"18px"}}><strike>₹150</strike></span></span> </div>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="item col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <div class="item-inner">
                        <div class="item-img">
                          <div class="item-img-info">
                            <a
                              onClick={() => window.location.href = `/shopdetails/` + 27}
                              class="product-image"
                            >
                              <img
                                src= {img7}
                                // { process.env.REACT_APP_API + x.category_image }
                                alt="category_name"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="item-info">
                          <div class="info-inner">
                            <div
                              class="item-title"
                              style={{ paddingTop: "20px" }}
                            >
                              <a
                                onClick={() => window.location.href = `/shopdetails/` + 27}
                                style={{
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                }}
                              >
                                Urad Dal Porridge Mix
                                {/* {Truncate(x.category_name, 25)} */}
                              </a>{" "}
                              {/* <div class="item-price" style={{paddingTop:"14px"}}>
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹125</span> <span style={{fontSize:"18px"}}><strike>₹150</strike></span></span> </div>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* end new add */}
                {/* //   ); */}
                {/* // })} */}
                <ToastContainer Transition="zoom" />
              </ul>
            </div>
            {/* </div> */}
          </div>
        </div>

        {/* segment shop */}

        <div class="latest-blog wow bounceInUp animated animated container">
          <div class="best-pro slider-items-products container">
            <div class="new_title">
              <h2 style={{ border: "0" }}>Why Uyirtheeni?</h2>
            </div>
          </div>
          <div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 blog-post">
              <div class="blog_inner">
                <div class="blog-img">
                  {" "}
                  <a href="/whyuyir">
                    {" "}
                    <img
                      src="images/whyuyir3 - Copy.jpg"
                      alt="blog image"
                    />{" "}
                  </a>
                  <div class="mask">
                    {" "}
                    <a class="info" href="/whyuyir">
                      Read More
                    </a>{" "}
                  </div>
                </div>
                <div class="blog-info">
                  {/* <div class="post-date">
                <time class="entry-date" datetime="2015-05-11T11:07:27+00:00">26 <span>June</span></time>
              </div>
              <ul class="post-meta">
                <li><i class="fa fa-user"></i>Posted by <a href="#">admin</a> </li>
                <li><i class="fa fa-comments"></i><a href="#">4 comments</a> </li>
              </ul> */}
                  <h3>
                    <Link to="/whyuyir">
                      Millets that added to processed foods to enrich them.
                    </Link>
                  </h3>
                  <p style={{ textAlign: "justify" }}>
                    Millets are rich in niacin, which helps your body manage
                    more than 400 enzyme reactions. Niacin is also important for
                    healthy skin and organ function.. This natural pigment acts
                    as both an antioxidant and as a precursor to vitamin A.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-6 blog-post">
              <div class="blog_inner">
                <div class="blog-img">
                  {" "}
                  <a href="/whyuyir">
                    {" "}
                    <img src="images/27-824-465.jpg" alt="blog image" />{" "}
                  </a>
                  <div class="mask">
                    {" "}
                    <a class="info" href="/whyuyir">
                      Read More
                    </a>{" "}
                  </div>
                </div>
                <div class="blog-info">
                  {/* <div class="post-date">
                <time class="entry-date" datetime="2015-05-11T11:07:27+00:00">30 <span>June</span></time>
              </div>
              <ul class="post-meta">
                <li><i class="fa fa-user"></i>Posted by <a href="#">admin</a> </li>
                <li><i class="fa fa-comments"></i><a href="#">6 comments</a> </li>
              </ul> */}
                  <h3>
                    <Link to="/whyuyir">Fresh Wood Pressed Groundnut Oil.</Link>
                  </h3>
                  <p style={{ textAlign: "justify" }}>
                    It contains antioxidants that shield the body from toxins
                    and free radicals. Groundnut oil additionally contains
                    vitamin E and phytochemicals that aids in diminishing
                    aggravation and battling cancer cells.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row our-features-box">
          <ul>
            <li>
              <div class="feature-box">
                <div class="icon-truck"></div>
                <div class="content">FREE SHIPPING ON ORDER ABOVE RS.1000</div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-support"></div>
                <div class="content">
                  Have a question?
                  <br />
                  <a href="tel:+91 90959-59587"> +91 86103 80320</a>
                </div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-money"></div>
                <div class="content">
                  NO REFUND POLICY & 10 DAYS RETURN SERVICE AVAILABLE
                </div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-return"></div>
                <div class="content">
                  Entice yourself with delicious sweets!
                </div>
              </div>
            </li>
            <li>
              {/* <div class="feature-box">
                {" "}
                <a href="#">
                  <i class="fa fa-apple"></i> download
                </a>{" "}
                <a href="#">
                  <i class="fa fa-android"></i> download
                </a>{" "}
              </div> */}
            </li>
            <li style={{ marginTop: "70px", marginBottom: "10px" }}>
              <button
                type="button"
                title="shop"
                class="button btn-continue"
                onClick={Updateee}
              >
                <span>GO TO SHOPPING</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;
