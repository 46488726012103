import React,{useState} from "react";
import {useForm} from "react-hook-form";
import { FaPhoneAlt } from 'react-icons/fa';  
import { GrMail } from 'react-icons/gr';  
import { MdLocationPin } from 'react-icons/md';  
import { Link } from "react-router-dom"; 


const Contact = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = (data, e) => {
    e.target.reset();
    // setUserData(JSON.stringify(data));
    console.log("Message submited: " + JSON.stringify(data));
  };

  function handleclick() {
    
  }

  function Updateee() {

    window.location.href = `/shop`
    // props.history.push("/Product?id=" + id)
  }
  return (
    <div>
      <div class="page-heading">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <div class="page-title">
                <li class="home">
                  {" "}
                  <Link to="/" title="Go to Home Page">
                    Home
                  </Link>{" "}
                  <span>&rsaquo; </span>{" "}
                </li>

                <h2>Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <!-- BEGIN Main Container col2-right --> */}
  <div class="main-container col2-right-layout">
    <div class="main container">
      <div class="row">
        <div class="col-main col-sm-9 wow bounceInUp animated animated" style={{visibility: "visible"}}>
          <div id="messages_product_view"></div>
          <form action="#" id="contactForm" method="post" onSubmit={handleSubmit(onSubmit)}>
            <div class="static-contain">
              <fieldset class="group-select">
                <ul>
                  <li id="billing-new-address-form">
                    <fieldset class="">
                      <ul>
                        <li>
                          <div class="customer-name">
                            <div class="input-box name-firstname">
                              {/* <label for="name"><em class="required">*</em>Name</label> */}
                              <br/>
                              <input name="name" id="name" title="Name" onClick={(e)=>setName(e.target.value)} class="input-text required-entry" placeholder="Enter a name" type="text"/>
                            </div>
                            <div class="input-box name-firstname">
                              {/* <label for="email"><em class="required">*</em>Email</label> */}
                              <br/>
                              <input name="email" id="email" title="Email" onClick={(e)=>setEmail(e.target.value)} placeholder="Enter a email" class="input-text required-entry validate-email" type="text"/>
                            </div>
                          </div>
                        </li>
                        <li>
                          {/* <label for="telephone">Telephone</label> */}
                          <br/>
                          <input name="telephone" id="telephone" title="Telephone" onClick={(e)=>setNumber(e.target.value)}  placeholder="Enter a mobile number" class="input-text" type="text"/>
                        </li>
                        <li>
                          {/* <label for="comment"><em class="required">*</em>Comment</label> */}
                          <br/>
                          <textarea name="comment" id="comment" title="Comment" onClick={(e)=>setMessage(e.target.value)} class="required-entry input-text" cols="5" rows="3" placeholder="Enter a message"></textarea>
                        </li>
                      </ul>
                    </fieldset>
                  </li>
                  {/* <p class="require"><em class="required">* </em>Required Fields</p>
                  <input type="text" name="hideit" id="hideit" value="" style={{display:"none !important"}}/> */}
                  <div class="buttons-set">
                  <a href={`mailto:info@uyirtheeni.com?Mobile Name= ${name}&body=User Number : ${number}%0D%0A%0D%0A Email : ${email}%0D%0A%0D%0A Message : ${message}`}><button type="submit" title="Submit" class="button submit"><span><span>Send</span> </span></button></a>
                  </div>
                </ul>
              </fieldset>
            </div>
          </form>
          
        </div>
        <aside class="col-right sidebar col-sm-3 wow bounceInUp animated animated" style={{visibility: "visible"}}>
          <div class="block block-company">
            <div class="block-title">Company</div>
            <div class="block-content">
              <ol id="recently-viewed-items">
                <li class="item odd"><Link to="/about">About Us</Link></li>
                <li class="item even"><Link to="/whyuyir">Why Uyirtheeni?</Link></li>
                <li class="item  odd"><Link to="/termsandcondition">Terms of Service</Link></li>
                <li class="item last"><Link to="/privacypolicy">Privacy Policy</Link></li>
                <li class="item last"><Link to="/contact"><strong>Contact Us</strong></Link></li>
              </ol>
            </div>
          </div>
        </aside>
        {/* <!--col-right sidebar-->  */}
      </div>
      {/* <!--row-->  */}
    </div>
    {/* <!--main-container-inner-->  */}
  </div>

      <div class="container">
        <div class="row our-features-box">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="feature-box">
                {/* <div class="icon-truck"></div> */}
                <a href="mailto:online@uyirtheeni.com"> <GrMail style={{fontSize: "26px",marginBottom:"26px",lineHeight:"60px",cursor:"pointer",width:"65px",color:" #80b435"}}/></a>
                <div class="content"><a href="mailto:online@uyirtheeni.com"> <br />online@uyirtheeni.com</a></div>
              </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="feature-box">
                {/* <div class="icon-support"></div> */}
                <a href="tel:+91 90959-59587"><FaPhoneAlt style={{fontSize: "26px",marginBottom:"26px",cursor:"pointer",lineHeight:"60px",width:"65px",color:" #80b435"}}/></a>
                <div class="content">
                  {/* Have a question? */}
                  <br />
                  <a href="tel:+91 90959-59587"> +91 86103 80320</a>
                </div>
              </div>
              </div>
          
            
            <div class="col-lg-4 col-md-4 col-sm-4" style={{paddingRight:"0px"}}>
              <div class="feature-box">
                {/* <div class="icon-money"></div>  */}
                <MdLocationPin style={{fontSize: "26px",marginBottom:"26px",lineHeight:"60px",width:"65px",color:" #80b435"}}/>
                <div class="content">Uyirtheeni - Farmer's Creation
                No.58/30, Paari Nagar, Palayapalayam,
                Erode - 638 011</div>
              </div>
              </div>
          
            {/* <li>
              <div class="feature-box">
                <div class="icon-return"></div>
                <div class="content">30 days return Service</div>
              </div>
            </li>
            <li class="last">
              <div class="feature-box">
                {" "}
                <a href="#">
                  <i class="fa fa-apple"></i> download
                </a>{" "}
                <a href="#">
                  <i class="fa fa-android"></i> download
                </a>{" "}
              </div>
            </li> */}
        
        </div>
      </div>

      {/* <!-- BEGIN Main Container col2-right --> */}
      <div class="main-container col2-right-layout" style={{marginBottom:"30px"}}>
        <div class="main container">
          <div class="row">
            <div
              class="col-main col-sm-12 wow bounceInUp animated animated"
              style={{ visibility: "visible" }}
            >
              <div id="messages_product_view"></div>
              <form action="#" id="contactForm" method="post">
              </form>
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31326.15575130541!2d77.693463!3d11.055903!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb21fd7e6668e1f9!2sNalam%20Agro%20Foods!5e0!3m2!1sen!2sin!4v1630991819632!5m2!1sen!2sin"
                width="100%"
                height="500px"
                style={{ border: "0" }}
                allowfullscreen="true"
                loading="lazy"
              ></iframe> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3912.0587169247956!2d77.6938504280247!3d11.330419026079452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba96f625c4c7f1f%3A0x96c8749a1dc7a176!2sUyirtheeni%20-%20Farmer&#39;s%20Creation!5e0!3m2!1sen!2sin!4v1668498820936!5m2!1sen!2sin" width="100%" height="500px" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            {/* <!--col-right sidebar-->  */}
          </div>
          {/* <!--row-->  */}
        </div>
        {/* <!--main-container-inner-->  */}
      </div>
      {/* <!--main-container col2-left-layout-->  */}

      <div class="container">
        <div class="row our-features-box">
          <ul>
            <li>
              <div class="feature-box">
                <div class="icon-truck"></div>
                <div class="content">FREE SHIPPING ON ORDER ABOVE RS.1000</div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-support"></div>
                <div class="content">
                  Have a question?
                  <br />
                  <a href="tel:+91 90959-59587"> +91 86103 80320</a>
                </div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-money"></div>
                <div class="content">NO REFUND POLICY & 10 DAYS RETURN SERVICE AVAILABLE</div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-return"></div>
                <div class="content">Entice yourself with delicious sweets!</div>
              </div>
            </li>
            <li >
              {/* <div class="feature-box">
                        {" "}
                        <a href="#">
                          <i class="fa fa-apple"></i> download
                        </a>{" "}
                        <a href="#">
                          <i class="fa fa-android"></i> download
                        </a>{" "}
                      </div> */}
            </li>
            <li style={{ marginTop: "70px", marginBottom: "10px" }}>
              <button type="button" title="shop" class="button btn-continue" onClick={Updateee}><span>GO TO SHOPPING</span></button>
            </li>
          </ul>
        </div>
      </div>
      
    </div>
  );
};

export default Contact;


{/* <FaPhoneAlt style={{fontSize: "26px",marginBottom:"26px",lineHeight:"60px",width:"65px",color:" #80b435"}}/> 
    <MdLocationPin style={{fontSize: "26px",marginBottom:"26px",lineHeight:"60px",width:"65px",color:" #80b435"}}/>
    <GrMail style={{fontSize: "26px",marginBottom:"26px",lineHeight:"60px",width:"65px",color:" #80b435"}}/>
*/}
