import React,{useEffect,useState} from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Link, useLocation, useNavigate, withRouter } from "react-router-dom";
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';


const token = (localStorage.getItem('tok'));
const Shop = (props) => {
  const [product, setProduct] = useState([])
  const [load, setLoad] = useState(true);
  const history = useNavigate()

  useEffect(() => {
    if (localStorage.tok) {
      axios.post(`${process.env.REACT_APP_API}/customer/getallProduct`, {},
        { headers: { "authtoken": `${token}` } }).then((res) => {
          setLoad(false)
          if (res.data.data.length !== 0)
            setProduct(res.data.data)
          else {
            setProduct("No Product Found")
          }
        });
    }
    else {
      axios.get(`${process.env.REACT_APP_API}/customer/getallProducts`).then((res) => {
        setLoad(false)
        if (res.data.data.length !== 0)
          setProduct(res.data.data)
        else {
          setProduct("No Product Found")
        }
      });
    }
  }, []);

  function Update(id) {
    window.location.href=`/shopdetails/`+id
    // props.history.push("/Product?id=" + id)
  }

    function Updateee() {
      window.location.href = `/shop`
      // props.history.push("/Product?id=" + id)
    }

  function wishlistme(x) {
    if (localStorage.tok) {
      const product_id = x.id
      if (x.favourites == null) {
        axios.post(`${process.env.REACT_APP_API}/customer/addWishlist`, {
          "product_id": product_id,
        },
          { headers: { "authtoken": `${token}` } })
          .then((res) => {
            var response = res.data.data;
            toast.success(response, { autoClose: 2000, position: "bottom-center" })
            function reloadFunction() {
              window.location.reload();
            }
            setTimeout(reloadFunction, 2500);
            window.scrollTo(0, 0);
            axios.post(`${process.env.REACT_APP_API}/customer/getallProduct`, {}, { headers: { "authtoken": `${token}` } })
              .then((res) => {
                setLoad(false)
                  ([])
                setProduct(res.data.data);
                // toast.success(response, { autoClose: 2000, position: "bottom-center" })
                // window.location.reload()

              })
          })
      } else {
        const product_id = x.id
        axios.post(`${process.env.REACT_APP_API}/customer/removeWishlist`, {
          "product_id": product_id,
        },
          {
            headers: { "authtoken": `${token}` }
          }).then((res) => {
            var message = res.data.data;
            setLoad(false)
            axios.post(`${process.env.REACT_APP_API}/customer/getallProduct`, {}, { headers: { "authtoken": `${token}` } }).then((res) => {
              setProduct([]);
              setProduct(res.data.data);
              toast.error(message, { autoClose: 2000, position: "bottom-center" })
            });
          });
      }
    }
    else {
      // history.push("/Login")
      window.location.href="login"
      toast.success("Please Login", { autoClose: 2000, position: "bottom-center" })
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div class="page-heading">
        <div class="breadcrumbs">
          <div class="container">
            <div class="row">
              <div class="col-xs-12">
                <ul>
                  <li class="home"> <Link to="/" title="Go to Home Page">Home</Link> <span>&rsaquo; </span> </li>
                  <li class="category1601"> <strong>Products</strong> </li>
                </ul>
              </div>
              {/* <!--col-xs-12-->  */}
            </div>
            {/* <!--row-->  */}
          </div>
          {/* <!--container-->  */}
        </div>
        <div class="page-title">
          <h2>Products</h2>
        </div>
      </div>

      <section>
        {/* <!-- For version 1, 2, 3, 8 -->  */}
        {/* <!-- For version 1, 2, 3 --> */}
        <div class="container">
          <div class="row">
            <div >
              <div class="pro-coloumn">
                <article>
                <h1
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Products
                  </h1>
                  <div class="category-products">
                    <ul class="products-grid">
                    {Array.isArray(product) && product.length != 0 ?product.map((x) => {
                      return (
                      <li class="item col-lg-4 col-md-3 col-sm-4 col-xs-6">
                        <div class="item-inner">
                          <div class="item-img">
                            <div class="item-img-info" style={{padding:"30px"}}>
                                <a onClick={() => Update(x.id)} class="product-image"><img src={process.env.REACT_APP_API + x.product_image} alt="Farmers Product" /></a>

                            </div>
                            {/* <div class="add_cart">
                              <button class="button " type="button" onClick={() => Update(x.id)} ><span>View</span></button>
                            </div> */}
                          </div>
                          <div class="item-info">
                            <div class="info-inner">
                              <div class="item-title"><a onClick={() => Update(x.id)} style={{textTransform:"capitalize",fontSize:"22px",cursor:"pointer"}}>{x.product_name} </a>
                              <div class="item-title"><a style={{textTransform:"capitalize",fontSize:"18px"}}>( {x.category_name} ) </a> </div>
                              <div className="pt-3" onClick={() => wishlistme(x)} >
                                    <span style={{ fontSize: "30px", color: "green", }}>{x.favourites == null ? <AiOutlineHeart/> : <AiFillHeart/>}</span>
                                  </div>
                                  </div>
                              <div class="item-price">
                                      <div class="price-box"><span class="regular-price"><span class="price" style={{color: "green",fontSize: "22px" }}>₹{x?.product_variants[0]?.discount_price}</span> <span style={{fontSize:"18px"}}><strike>₹{x?.product_variants[0]?.actual_price}</strike></span></span> </div>
                                    </div>
                            </div>
                          </div>
                        </div>

                      </li>
                         )
                        }):(
                          <h3 className="container text-center" style={{fontWeight:"bold"}}>"No Product Found"</h3>
                        )
                        }
                        <ToastContainer/>
                    </ul>
                  </div>
                  {/* <div class="toolbar bottom">
                    <div class="display-product-option">
                      <div class="pages">
                        <label>Page:</label>
                        <ul class="pagination">
                          <li><a href="#">«</a></li>
                          <li class="active"><a href="#">1</a></li>
                          <li><a href="#">2</a></li>
                          <li><a href="#">3</a></li>
                          <li><a href="#">»</a></li>
                        </ul>
                      </div>
                      <div class="product-option-right">
                        <div class="sort-by">
                          <label class="left">Sort By: </label>
                          <ul>
                            <li><a href="#">Position<span class="right-arrow"></span></a>
                              <ul>
                                <li><a href="#">Name</a></li>
                                <li><a href="#">Price</a></li>
                                <li><a href="#">Position</a></li>
                              </ul>
                            </li>
                          </ul>
                          <a class="button-asc left" href="#" title="Set Descending Direction"><span class="top_arrow"></span></a> </div>
                        <div class="pager">
                          <div class="limiter">
                            <label>View: </label>
                            <ul>
                              <li><a href="#">15<span class="right-arrow"></span></a>
                                <ul>
                                  <li><a href="#">20</a></li>
                                  <li><a href="#">30</a></li>
                                  <li><a href="#">35</a></li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* footer above */}
      <div class="container">
        <div class="row our-features-box">
          <ul>
            <li>
              <div class="feature-box">
                <div class="icon-truck"></div>
                <div class="content">FREE SHIPPING ON ORDER ABOVE RS.1000</div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-support"></div>
                <div class="content">
                  Have a question?
                  <br />
                  <a href="tel:+91 90959-59587"> +91 86103 80320</a>
                </div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-money"></div>
                <div class="content">NO REFUND POLICY & 10 DAYS RETURN SERVICE AVAILABLE</div>
              </div>
            </li>
            <li>
              <div class="feature-box">
                <div class="icon-return"></div>
                <div class="content">Entice yourself with delicious sweets!</div>
              </div>
            </li>
              <li>
                {/* <div class="feature-box">
                {" "}
                <a href="#">
                  <i class="fa fa-apple"></i> download
                </a>{" "}
                <a href="#">
                  <i class="fa fa-android"></i> download
                </a>{" "}
              </div> */}
              </li>
              <li style={{ marginTop: "70px", marginBottom: "10px" }}>
                <button type="button" title="shop" class=" button btn-continue" onClick={Updateee}><span>GO TO SHOPPING</span></button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Shop